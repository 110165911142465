<template>
  <div>
    <ch-layout page search>
      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <div class="header" v-if="staffDetail.name">
          <div>{{staffDetail.name}}（{{staffDetail.phone}}）</div>
        </div>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:amount="scope">
            <span>
              {{scope.row.amount}}
              <el-tooltip class="item" effect="dark" content="保证好服务质量！" placement="top">
                <ch-icon v-if="scope.row.payType===3" style="font-size: 20px;margin-right: 5px" name="payTypeBefore" />
              </el-tooltip>
            </span>
          </template>
          <template v-slot:status="scope">
            <div v-if="scope.row.status===1" style="color: #3d7fff">{{status[scope.row.status]}}</div>
            <div v-else-if="scope.row.status===4" style="color: #12a182">{{status[scope.row.status]}}</div>
            <div v-else-if="scope.row.status===5" style="color: #f1ca17">{{status[scope.row.status]}}</div>
            <div v-else-if="scope.row.status===7|| scope.row.status===11" style="color: #EB0A0A">{{status[scope.row.status]}}</div>
            <div v-else>{{status[scope.row.status]}}</div>
          </template>
          <template v-slot:handle="scope">
            <ch-button type="link" @click="toPage(scope.row.id)">详情</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
  </div>
</template>

<script>
export default {
  data(){
    return{
      // 搜索相关
      searchForm: {
        goodsName:null,
        orderNo:null,
        status:null,
        serviceStaffId:null,
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      status:['待支付','待服务','已取消','已评价','已完成','服务中','待验收','待接单','待签到','申诉中','夜间待支付','爽约'],
      staffDetail:{},
    }
  },
  computed:{
    searchOption: function (){
      return [
        {type: 'input', label: '服务名称：', prop: 'goodsName', placeholder: "请输入服务名称",},
        {type: 'input', label: '服务订单号：', prop: 'orderNo', placeholder: "请输入订单号",width: '220px',labelWidth: '80px'},
        {
          type: "select", label: "状态：", prop: "status", placeholder: "请选择状态",labelWidth: '44px',
          name: "label", value: "value", option: [
            {value: 0, label: "待支付"},
            {value: 1, label: "待服务"},
            {value: 2, label: "已取消"},
            {value: 3, label: "已评价"},
            {value: 4, label: "已完成"},
            {value: 5, label: "服务中"},
            {value: 6, label: "待验收"},
            {value: 7, label: "待接单"},
            {value: 8, label: "待签到"},
            {value: 9, label: "申诉中"},
            {value: 10, label: "夜间待支付"},
            {value: 11, label: "爽约"},
          ]
        },
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.resetSearch},
          ]},

      ]
    },
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "服务名称", prop: "goodsName", showOverflowTooltip: true,props:{minWidth: '100px'}},
        { column: "text", label: "用户手机号", prop: "telNumber", showOverflowTooltip: true,props:{minWidth: '100px'}},
        { column: "text", label: "服务订单号", prop: "serverNumber", showOverflowTooltip: true,props:{minWidth: '170px'}},
        { column: "text", label: "交易订单号", prop: "orderNumber", showOverflowTooltip: true,props:{minWidth: '170px'}},
        { column: "text", label: "下单时间", prop: "createTime", showOverflowTooltip: true,props:{minWidth: '140px'}},
        { column: "text", label: "服务开始时间", prop: "startTime", showOverflowTooltip: true,props:{minWidth: '140px'}},
        { column: "slot", label: "本单金额", slotName: "amount",showOverflowTooltip:true,},
        { column: "slot", label: "状态", slotName: "status",showOverflowTooltip:true,},
        { column: "slot", label: "操作", slotName: "handle", width: "80", fixed: 'right' },
      ];
    },
  },
  activated() {
    this.getTableList()
    this.getStaffDetail()
  },
  methods:{
    // 查询
    searchHandle() {
      this.getTableList()
    },
    resetSearch() {
      this.getTableList({})
    },
    // 列表数据
    async getTableList() {
      this.isTableLoading = true;
      let params = {
        ...this.searchForm,
      }
      params.serviceStaffId = this.$route.query.id
      this.$refs?.page?.getServerList("/hm/serviceStaff/getOrderList",params).then((res) => {
        this.tableList = res.rows
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    getStaffDetail() {
      this.$curl.get(`/hm/serviceStaff/staffDetail/${this.$route.query.id}`).then((res) => {
        this.staffDetail = res.data
      })
    },
    // 跳转详情
    toPage(orderId){
      this.$router.push({ name: "serviceDetail", query: { orderId } });
    },
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  line-height: 44px;
  margin: 0 10px;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
}
.circle_green{
  background: #00ff00;
}
.circle_red{
  background: #ff0000;
}
</style>